@import 'app.scss';
.wrapper {
  background-color: var(--color-bg-default);
}

.content {
  --pin-size: 3rem;

  position: relative;
  grid-column: 1 / -1;
  margin-bottom: 0;
  padding: var(--section-vertical-spacing) 0;

  &::before {
    position: absolute;
    top: calc(var(--section-vertical-spacing) + spacing(1));
    bottom: var(--section-vertical-spacing);
    left: calc(var(--pin-size) / 2);
    transform: translate(-50%);
    background-color: var(--color-black-300);
    width: 1px;
    content: '';
  }

  .inStore & {
    grid-column: 3 / span 8;
  }

  @include from-breakpoint(md) {
    grid-column: 2 / span 10;
  }

  @include from-breakpoint(lg) {
    grid-column: 4 / span 6;
  }
}
