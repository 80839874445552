@import 'app.scss';
.hours {
  display: block;
  line-height: 1.5;
}

.exception {
  @include typography-paragraph-lg;

  text-align: left !important;
}
