@import 'app.scss';
.container {
  position: relative;
  z-index: 0;
  min-height: 30rem;

  &.regular {
    height: 50vh;
  }

  &.large {
    height: calc(90lvh - #{spacing(16)});
  }

  &.store {
    flex: 1;

    .popup {
      /* stylelint-disable-next-line selector-pseudo-class-no-unknown, selector-class-pattern */
      :global(.leaflet-popup-content) {
        width: 380px !important;
      }
    }
  }

  &.storeFixed {
    height: 485px;
  }
}

.map {
  width: 100%;
  height: 100%;
}

.marker {
  position: relative;
  width: 3rem;
  height: 3.75rem;
}

.storeMarker {
  position: relative;
  border: var(--color-white) 3px solid;
  border-radius: 50%;
  box-shadow: 0 4px 4px 0 #0000001a;
  background-color: var(--color-purple-500);
  width: 2.25rem;
  height: 2.25rem;
}

.pin {
  position: absolute;
  left: 0;
  width: 0;
  width: 100%;
  height: 100%;
  color: var(--color-red-500);
  stroke: var(--color-red-600);
}

.icon {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 24px;
  height: 24px;
  color: var(--color-white);
}

.index {
  display: block;
  display: flex;
  position: absolute;
  top: spacing(-1);
  right: spacing(-1);
  align-items: center;
  justify-content: center;
  z-index: 999;
  border-radius: 40%;
  background-color: var(--color-fg-brand);
  width: 1.25rem;
  height: 1.25rem;
  color: var(--color-bg-brand);
  font-size: 0.875rem;
  font-weight: 500;
}

.cluster {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color var(--transition-base);
  border-radius: 50%;
  background-color: var(--color-bg-brand);
  width: 2rem;
  height: 2rem;
  color: var(--color-fg-brand);

  &:hover {
    background-color: var(--color-bg-brand-hover);
  }
}

.popup {
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global(a) {
    color: var(--color-text-base); // overwriting css from leaflet package
  }
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown, selector-class-pattern */
  :global(.leaflet-popup-content-wrapper) {
    border-radius: var(--border-radius-base);
    padding: 0;
  }
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown, selector-class-pattern */
  :global(.leaflet-popup-content) {
    margin: 0;

    // Magic number to match design
    width: 312px !important;
  }
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown, selector-class-pattern */
  :global(.leaflet-popup-close-button) {
    display: none;
  }

  &.flatPopup {
    /* stylelint-disable-next-line selector-pseudo-class-no-unknown, selector-class-pattern */
    :global(.leaflet-popup-content) {
      // Magic number to match design
      width: 460px !important;
    }
  }
}

.popupAddress {
  margin-top: spacing(2);
  margin-bottom: 0;
}

.popupButton {
  display: flex;
  margin: 0 auto;
  margin-top: spacing(5);
}

[data-theme='BREDA_STRAALT'] {
  .pin {
    color: var(--color-breda-straalt);
    stroke: var(--color-breda-straalt);
  }
}
