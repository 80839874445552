@import 'app.scss';
.wrapper {
  display: grid;
  position: relative;
  grid-template-columns: repeat(12, 1fr);
  grid-column: var(--section-width, 1 / -1);
  grid-gap: spacing(2);
  align-items: center;
  border-radius: var(--border-radius-base);
  background-color: var(--color-bg-brand);
  padding: spacing(6);
  width: 100%;
  color: var(--color-fg-brand);

  @include from-breakpoint(md) {
    grid-column: var(--section-width, 2 / span 10);
  }

  @include from-breakpoint(lg) {
    grid-column: var(--section-width, 4 / span 6);
  }

  @include from-breakpoint(md) {
    padding: spacing(12);
  }
}

.content {
  grid-column: 1 / -1;
  margin-bottom: 0;
  padding-right: calc(var(--content-padding) * 2);
  padding-left: var(--content-padding);
}

.ctaContainer {
  grid-column: 1 / -1;
  margin-top: spacing(6);
}

.primary {
  background-color: var(--color-bg-primary);
  color: var(--color-fg-primary);
}

.secondary {
  background-color: var(--color-bg-secondary);
  color: var(--color-fg-secondary);
}

/* stylelint-disable-next-line selector-class-pattern */
.wrapper.BREDA_STRAALT {
  background-color: var(--color-breda-straalt);
  color: var(--color-white);
}
