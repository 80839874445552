@import 'app.scss';
.item {
  position: relative;
  padding-top: spacing(1);
  padding-left: spacing(16);
  min-height: 4rem;

  &:not(:last-child) {
    padding-bottom: spacing(8);
  }
}

.pin {
  position: absolute;
  left: 0;
  width: var(--pin-size);
  color: var(--color-bg-brand);
}

.icon {
  position: absolute;
  top: 0.875rem; // custom to center in pin
  left: calc(var(--pin-size) / 2);
  transform: translateX(-50%);
  color: var(--color-fg-brand);
}

.itemTitle {
  display: block;
  margin-bottom: spacing(1);
}

.itemContent {
  display: block;
  position: relative;
  transition: var(--transition-base);
  border-radius: var(--border-radius-base);
  background-color: var(--color-bg-default);
  padding: spacing(2);
  padding-right: calc(spacing(2) + 1rem);
  width: 100%;
  text-align: left;
}
