@import 'app.scss';
.table {
  grid-column: 1 / -1;
  margin-bottom: 0;
  width: 100%;
  border-collapse: collapse;

  @include from-breakpoint(md) {
    grid-column: 2 / span 10;
  }

  @include from-breakpoint(lg) {
    grid-column: 4 / span 6;
  }
}

.tr {
  background-color: var(--color-bg-default);

  &:nth-child(odd) {
    background-color: var(--color-bg-alternative);
  }
}

.td {
  display: block;
  padding: 0 spacing(4) spacing(4) spacing(4);
  white-space: pre-line;

  &:first-child {
    border-top-left-radius: var(--border-radius-base);
    border-bottom-left-radius: var(--border-radius-base);
    padding-top: spacing(4);
  }

  &:last-child {
    border-top-right-radius: var(--border-radius-base);
    border-bottom-right-radius: var(--border-radius-base);
  }

  @include from-breakpoint(xs) {
    display: table-cell;
    padding: spacing(4);

    &:last-child:not(:first-child) {
      text-align: right;
    }
  }
}
