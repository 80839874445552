@import 'app.scss';
.icon {
  color: var(--color-red-500);
}

.input {
  padding-right: calc(var(--icon-size) + var(--icon-spacing) * 2);
}

.clear {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 2.5rem;
  height: 2.5rem;
}

.clearIcon {
  width: 1rem;
  height: 1rem;
}

.withButton {
  --button-size: var(--input-height);

  .input {
    padding-right: calc(
      var(--icon-size) + var(--button-size) + var(--icon-spacing)
    );
  }

  .clear {
    right: var(--button-size);
  }
}
