@import 'app.scss';
.container {
  @include container-width;

  display: flex;
  flex-wrap: wrap;
  gap: spacing(3);
  margin-bottom: spacing(8);

  @include from-breakpoint(xl) {
    flex-wrap: nowrap;
    margin-bottom: spacing(10);
  }
}

.filterButton {
  width: 100%;

  @include from-breakpoint(lg) {
    width: unset;
  }
}

.filterGroup {
  margin-bottom: 0;
  border-bottom: var(--color-black-100) 1px solid;
}

.filterGroupTitle {
  @include typography-paragraph-lg;

  width: 100%;
}

.filterGroupButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: spacing(4) spacing(1);
  width: 100%;
  color: var(--color-fg-default);

  &:focus-visible {
    outline: 1px dotted var(--color-fg-secondary);
    border-radius: var(--border-radius-base);
  }

  &:hover {
    .filterGroupButtonText {
      text-decoration: underline;
      text-underline-offset: 0.125rem;
    }
  }
}

.filterGroupContent {
  transition: var(--transition-duration-collapse);

  &.fadeEnter {
    height: 0;
    overflow: hidden;
  }

  &.fadeEnterActive {
    height: var(--item-height);
  }

  &.fadeExit {
    height: var(--item-height);
    overflow: hidden;
  }

  &.fadeExitActive {
    height: 0;
  }
}

.filterGroupContentWrapper {
  display: flex;
  flex-direction: column;
  gap: spacing(4);
  padding-top: spacing(2);
  padding-bottom: spacing(6);
}

.iconContainer {
  display: flex;
  gap: spacing(2);
  align-items: center;
}

.filterCount {
  @include typography-paragraph-xs;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-small);
  background-color: var(--color-orange-500);
  padding: 0.625rem;
  width: 1rem;
  height: 1rem;
  line-height: 1;
  color: var(--color-white);
}

.svgIcon {
  transition: var(--transition-base);
  width: 1rem;
}

.modalButtonWrapper {
  display: flex;
  gap: spacing(3);
}

.showResultsButton {
  width: 100%;
}

.datePicker {
  flex-shrink: 0;
  width: 100%;

  @include from-breakpoint(md) {
    width: unset;
  }
}

.searchFilters {
  flex-wrap: nowrap;
  margin-top: spacing(-6);
  margin-bottom: spacing(6);
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .primaryFilter {
    flex-grow: 0;
  }

  .filterButton {
    order: -1;
  }
}

.searchFilterContainer {
  display: flex;
  flex-direction: column;
  gap: spacing(3);
  width: 100%;

  @include from-breakpoint(md) {
    flex-direction: row;
  }
}
