@import 'app.scss';
.container {
  display: flex;
  position: fixed;
  right: 0;
  bottom: 0;
  align-items: center;
  z-index: 99;
  box-shadow: 0 0 24px 0 rgb(0 0 0 / 0.1);
  background-color: white;
  padding: spacing(6) 0;
  width: 100%;
  text-align: center;

  @include from-breakpoint(md) {
    bottom: var(--horizontal-container-padding);
    box-shadow: none;
    background-color: transparent;
    padding: 0;
    width: 100%;
    max-width: 23.5rem;
  }
}

.button {
  width: 100%;
}
