@import 'app.scss';
.checkbox {
  --checkbox-size: 1.25rem;
  --checkbox-icon-size: 0.75rem;
  --checkbox-gap: #{spacing(3)};

  position: relative;
}

.labelWrapper {
  display: flex;
  gap: var(--checkbox-gap);
  align-items: center;
  transition: var(--transition-base);
  border: 1px solid var(--color-border-default);
  border-radius: var(--border-radius-base);
  box-shadow: var(--box-shadow-card);
  padding: spacing(3) spacing(5);
  color: var(--color-black-300);

  &:hover {
    box-shadow: none;
  }

  .input:focus-visible ~ & {
    @include generic-focus;
  }

  .input:focus-visible ~ &,
  &:hover {
    color: var(--color-fg-default);

    .indicator {
      border-color: var(--color-orange-400);
    }
  }

  .input:checked ~ & {
    color: var(--color-fg-default);

    .indicator {
      border-color: var(--color-orange-500);
    }

    .icon {
      opacity: 1;
    }
  }
}

.label {
  user-select: none;
  hyphens: auto;
}

.indicator {
  position: relative;
  transition: var(--transition-base);
  border: solid 1px var(--color-border-default);
  border-radius: var(--border-radius-extra-small);
  background-color: var(--color-bg-default);
  width: var(--checkbox-size);
  height: var(--checkbox-size);
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: var(--checkbox-icon-size);
  height: var(--checkbox-icon-size);
  color: var(--color-orange-500);
}

.group {
  transition: var(--transition-duration-collapse);

  &.fadeEnter {
    height: 0;
    overflow: hidden;
  }

  &.fadeEnterActive {
    height: var(--item-height);
  }

  &.fadeExit {
    height: var(--item-height);
    overflow: hidden;
  }

  &.fadeExitActive {
    height: 0;
  }
}

.groupContent {
  display: grid;
  gap: spacing(4);
  margin-left: spacing(6);
  padding-top: spacing(4);
  padding-bottom: spacing(2);
}

.expandButton {
  position: absolute;
  top: 50%;
  right: spacing(3);
  transform: translate(0, -50%);
  transition: var(--transition-base);
  padding: spacing(2);

  &:hover {
    outline: none;
    border-radius: var(--border-radius-extra-small);
    background-color: var(--color-black-100);
  }

  &:focus-visible {
    @include generic-focus;

    background-color: var(--color-white);
  }
}

.chevron {
  transition: inherit;
  width: 1rem;
  height: 1rem;

  &.expanded {
    transform: rotate(180deg);
  }
}

.indeterminateWrapper {
  position: relative;
}
