@import 'app.scss';
.item {
  position: relative;
  padding-top: spacing(1);
  padding-left: spacing(16);
  min-height: 4rem;

  &::before {
    position: absolute;
    bottom: 100%;
    left: calc(var(--pin-size) / 2);
    transform: translateX(-50%);
    background: linear-gradient(
      0deg,
      #fff,
      #fff 50%,
      transparent 50%,
      transparent 100%
    );
    background-size: 1px 10px;
    width: 2px;
    height: 1.5rem;
    content: '';
  }

  &:not(:last-child) {
    padding-bottom: spacing(8);
  }
}

.pin {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--pin-size);
  color: var(--color-bg-brand);
}

.icon {
  position: absolute;
  top: 0.75rem; // custom to center in pin
  left: calc(var(--pin-size) / 2);
  transform: translateX(-50%);
  color: var(--color-fg-brand);
}

.index {
  display: flex;
  position: absolute;
  top: 0;
  left: calc(var(--pin-size) * 0.9);
  align-items: center;
  justify-content: center;
  transform: translateX(-50%);
  border-radius: 40%;
  background-color: var(--color-fg-brand);
  width: 1rem;
  height: 1rem;
  color: var(--color-bg-brand);
  font-size: 0.875rem;
  font-weight: 500;
}

.chevron {
  position: absolute;
  top: 50%;
  right: spacing(2);
  transform: translateY(-50%);
  transform-origin: 50% 50%;
  transition: var(--transition-base);
  width: 1rem;
  height: 1rem;

  [aria-expanded='true'] & {
    transform: rotate(180deg) translateY(50%);
  }
}

.itemTitle {
  display: block;
  margin-bottom: spacing(1);
}

.itemButton {
  display: block;
  position: relative;
  transition: var(--transition-base);
  border-top-left-radius: var(--border-radius-base);
  border-top-right-radius: var(--border-radius-base);
  background-color: var(--color-bg-default);
  padding: spacing(2);
  padding-right: calc(spacing(2) + 1rem);
  padding-bottom: spacing(3);
  width: 100%;
  text-align: left;
  color: var(--color-fg-default);

  &:hover {
    .itemTitle {
      text-decoration: underline;
    }
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 1px solid var(--color-bg-brand);
  }
}

.img {
  position: relative;
  margin-top: spacing(4);
  border-radius: var(--border-radius-base);
  width: 100%;
  overflow: hidden;
  aspect-ratio: 16 / 9;
}

.cta {
  &:not(:first-child) {
    margin-top: spacing(4);
  }
}

.wrapper {
  transition: var(--transition-duration-collapse);
  overflow: hidden;
}

.content {
  padding: spacing(4) 0;
}

.fadeEnter {
  height: 0;
}

.fadeEnterActive {
  height: var(--item-height);
}

.fadeExit {
  height: var(--item-height);
}

.fadeExitActive {
  height: 0;
}
