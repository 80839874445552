@import 'app.scss';
.wrapper {
  grid-row-gap: 0;
  margin-bottom: 0;
  width: 100%;
}

.item {
  @include typography-paragraph-md;

  display: inline-flex;
  align-items: center;
  border-bottom: 1px solid var(--color-border-light);
  background-color: var(--bg-color-default);
  min-height: 50px;
  color: var(--color-fg-default);
  font-weight: 500;
}

.iconContainer {
  margin-right: spacing(3);
}

.svgIcon {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--color-red-500);
}

.wrapper.inStore {
  .item {
    font-size: 1.5rem;
  }
}
