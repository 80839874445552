@import 'app.scss';
.video {
  grid-column: var(--section-width, 1 / -1);
  border-radius: var(--border-radius-base);
  overflow: hidden;

  &:not(.fullWidth) {
    @include from-breakpoint(md) {
      grid-column: var(--section-width, 2 / span 10);
    }

    @include from-breakpoint(lg) {
      grid-column: var(--section-width, 4 / span 6);
    }
  }
}
