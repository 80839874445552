@import 'app.scss';
.container {
  background-color: var(--color-bg-default);
}

.list {
  display: flex;
  flex-wrap: wrap;
  grid-column: 1 / -1;
  gap: 0 spacing(4);
  align-items: center;
  margin-bottom: 0;
  font-weight: 500;

  .inStore & {
    grid-column: 1 / -1;
  }

  @include from-breakpoint(md) {
    grid-column: 2 / span 11;
    gap: 0 spacing(6);
  }

  @include from-breakpoint(lg) {
    grid-column: 4 / span 9;
  }
}

.item {
  display: flex;
  align-items: center;
  padding: spacing(3) 0;
}

.icon {
  margin-right: spacing(3);
  width: 1.5rem;
  height: 1.5rem;
  color: var(--color-red-500);
}

.duration {
  margin-left: spacing(1);
  font-weight: 400;
}
