@import 'app.scss';
$modal-breakpoint: md;

.backdrop {
  position: fixed;
  bottom: 0;
  left: 0;
  transition: var(--transition-duration-menu);
  opacity: 0;
  z-index: 200;
  background-color: var(--color-backdrop);
  cursor: pointer;
  width: 100%;
  height: 100%;
  pointer-events: none;

  &.showBackdrop {
    opacity: 1;
    pointer-events: all;
  }
}

.modal {
  display: flex;
  position: fixed;
  right: 0;
  bottom: 0;
  flex-direction: column;
  z-index: 201;
  border-top-left-radius: var(--border-radius-base);
  border-top-right-radius: var(--border-radius-base);
  background-color: var(--color-bg-default);
  cursor: default;
  width: 100%;
  height: 90dvh;

  &.fadeEnter {
    transform: translateY(100%);
  }

  &.fadeEnterActive {
    transform: translateY(0);
    transition: var(--transition-duration-menu);
  }

  &.fadeExit {
    transform: translateY(0);
  }

  &.fadeExitActive {
    transform: translateY(100%);
    transition: var(--transition-duration-menu);
  }

  @include from-breakpoint($modal-breakpoint) {
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--border-radius-base);
    width: 37.5rem; // 600px
    height: 100%;

    &.fadeEnter {
      transform: translateX(100%);
    }

    &.fadeEnterActive {
      transform: translateX(0);
      transition: var(--transition-duration-menu);
    }

    &.fadeExit {
      transform: translateX(0);
    }

    &.fadeExitActive {
      transform: translateX(100%);
      transition: var(--transition-duration-menu);
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-gray-100);
  padding-top: spacing(5);
  padding-bottom: spacing(3);
}

.title {
  margin-bottom: 0;
}

.content {
  flex: 1;
  overflow-y: auto;
}

.footer {
  box-shadow: var(--box-shadow-card);
  padding-top: spacing(4);
  padding-bottom: spacing(4);
}
