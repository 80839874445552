@import 'app.scss';
.item {
  display: grid;
  grid-template-columns: 1fr auto;
  background-color: var(--color-bg-default);
  padding: spacing(2) spacing(4);
  padding-right: 0;

  @include from-breakpoint(xl) {
    padding: spacing(3) spacing(6);
    padding-right: 0;
    height: 100%;
  }
}

.contentContainer {
  position: relative;
  padding-right: spacing(4);
  overflow: hidden;
}

.date {
  @include typography-paragraph-sm;

  color: var(--color-black-400);
}

.link {
  display: block;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.25rem;
  font-weight: 400;
}

.save {
  flex-shrink: 0;
}
