@import 'app.scss';
.form {
  position: relative;
  max-width: 44rem;
}

.submit {
  position: absolute;
  top: 0;
  right: 0;
}
