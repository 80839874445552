@import 'app.scss';
.wrapper {
  position: relative;
  flex-shrink: 0;
  height: var(--input-height);
}

.hidden {
  opacity: 0;
  width: 0;
  height: 0;
}

.button {
  width: 100%;
}
