@import 'app.scss';
.wrapper {
  margin-bottom: spacing(10);

  @include from-breakpoint(md) {
    margin-bottom: spacing(20);
  }
}

.total {
  margin-bottom: spacing(6);
  line-height: 1.25;
  font-size: 1.125rem;
  font-weight: 500;
}

.alternateGrid {
  @include from-breakpoint(lg) {
    & > :nth-child(3),
    & > :nth-child(4),
    & > :nth-child(8) {
      grid-column: span 2;
    }
  }
}
