@import 'app.scss';
/**
* Pagination
*/

.pagination {
  display: flex;
  flex-wrap: wrap;
  gap: spacing(5);
  align-items: center;
  justify-content: center;
  margin-bottom: spacing(10);

  @include from-breakpoint(md) {
    gap: spacint(4);
    margin-bottom: spacing(20);
  }

  @include from-breakpoint(lg) {
    gap: spacing(5);
  }
}

.current {
  background-color: var(--color-bg-default);
  color: var(--color-fg-on-default);
}

.current,
.page {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-base);
  box-shadow: var(--box-shadow-base);
  width: 3.125rem;
  min-width: 3.125rem;
  height: 3.125rem;

  &[disabled] {
    filter: grayscale(1);
  }
}

.page {
  background-color: transparent;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.prevPage,
.nextPage {
  display: flex;
  gap: spacing(2);
  align-items: center;
  transition: var(--transition-base);
  border-radius: var(--border-radius-base);
  background-color: var(--color-bg-primary);
  padding: 1.5rem 1.12rem;
  height: 3.125rem;
  color: var(--color-fg-brand);
  font-weight: 500;

  &:hover,
  &:focus {
    background-color: var(--color-bg-primary-hover);
    color: var(--color-fg-primary-hover);
  }

  &:disabled {
    background-color: var(--color-bg-disabled);
    color: var(--color-fg-disabled);
    pointer-events: none;
  }

  .icon {
    transition: var(--transition-base);
  }
}

.prevPage {
  flex-direction: row-reverse;

  &:hover,
  &:focus {
    &:not(:disabled) {
      .icon {
        transform: translate(-3px);
      }
    }
  }
}

.nextPage {
  &:hover,
  &:focus {
    &:not(:disabled) {
      .icon {
        transform: translate(3px);
      }
    }
  }
}

.quickLink {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition-base);
  border-radius: var(--border-radius-base);
  width: 3.125rem;
  min-width: 3.125rem;
  height: 3.125rem;

  &:hover,
  &:focus {
    background-color: var(--color-bg-primary-hover);
    cursor: pointer;
    color: var(--color-fg-primary-hover);
  }
}

.firstQuickLink {
  display: none;

  @include from-breakpoint(md) {
    display: block;
  }
}

.label {
  display: none;

  @include from-breakpoint(md) {
    display: block;
  }
}

.prevNextQuickLink {
  display: none;

  @include from-breakpoint(md) {
    display: flex;
  }
}

.prevNextQuickLinkMobile {
  display: flex;

  @include from-breakpoint(md) {
    display: none;
  }
}

.desktopQuickLink {
  display: none;

  @include from-breakpoint(md) {
    display: flex;
  }
}
