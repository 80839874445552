@import 'app.scss';
.wrapper {
  margin-top: spacing(6);
  background-color: var(--color-bg-default);

  @include from-breakpoint(md) {
    margin-top: spacing(8);
  }
}

.container {
  display: flex;

  @include from-breakpoint(md) {
    @include container-width;
  }
}

.filter {
  display: flex;
  flex: 1;
  column-gap: spacing(3);
  align-items: center;
  justify-content: center;
  border-bottom: 4px solid transparent;
  padding-top: spacing(4);
  padding-bottom: spacing(4);
  color: var(--color-fg-default);
  font-weight: 500;

  &.active {
    border-color: var(--color-red-500);
  }

  @include from-breakpoint(md) {
    flex: unset;
    padding-right: spacing(8);
    padding-left: spacing(8);
  }
}

.icon {
  color: var(--color-red-500);
}
