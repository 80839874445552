@import 'app.scss';
.container {
  grid-column: var(--section-width, 1 / -1);
  width: 100%;

  &:not(:first-child) {
    margin-top: 1rem;
  }

  @include from-breakpoint(md) {
    grid-column: var(--section-width, 2 / span 10);
  }

  @include from-breakpoint(lg) {
    grid-column: var(--section-width, 4 / span 6);
  }
}

.inPast {
  text-decoration: line-through;
  color: var(--color-black-400);
}

.underFold {
  display: none;
}

.lastRow {
  &:last-child {
    background-color: var(--color-white);
    padding-bottom: 0;
  }
}

.inStore {
  .datesTitle {
    margin-bottom: 20px;
    line-height: 0.9;
    font-size: 3.125rem;
  }
}
