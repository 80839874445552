@import 'app.scss';
.hero {
  --header-height: 72px;

  display: grid;
  position: relative;
  background: var(--color-bg-zigzag-hero);
  overflow: hidden;

  @include from-breakpoint(lg) {
    --header-height: 96px;

    grid-template-columns: auto 1fr;
  }
}

.contentContainer {
  @include container-width;

  position: relative;
  padding-bottom: spacing(10);

  @include from-breakpoint(lg) {
    padding-right: 0;
    padding-bottom: 0;
  }
}

.wrapper {
  @include from-breakpoint(lg) {
    padding-right: spacing(20);

    // The margin left value if we used the container width
    padding-left: calc(
      (
          100vw - 15px - var(--max-container-width) -
            var(--horizontal-container-padding) * 2
        ) / 2
    );
    max-width: none;
  }
}

.logoWrapper {
  display: flex;
  align-items: center;
  height: var(--panel-item-size);
}

.logo {
  margin-top: spacing(15);
  width: 30vw;
  height: auto;
  color: var(--color-fg-zigzag-hero);

  @include from-breakpoint(lg) {
    margin-top: spacing(22);
    width: 234px;
  }
}

.monthsContainer {
  --horizontal-spacing: #{spacing(3)};
  --vertical-spacing: #{spacing(6)};

  position: relative;
  margin: auto;
  width: 90vw;

  @include from-breakpoint(sm) {
    --horizontal-spacing: #{spacing(4)};
  }

  @include from-breakpoint(md) {
    --horizontal-spacing: #{spacing(6)};
    --vertical-spacing: #{spacing(8)};
  }

  @include from-breakpoint(lg) {
    --horizontal-spacing: #{spacing(12)};
    --vertical-spacing: #{spacing(14)};
    --before-height: 38px;
    --after-height: 45px;

    transform: skewX(-10deg);
    transform-origin: top right;
    margin: 0;
    margin-left: auto;
    padding-top: var(--before-height);
    padding-bottom: var(--after-height);
    width: 58vw;
    height: 70vh;
    min-height: 750px;

    &::before,
    &::after {
      display: block;
      position: absolute;
      left: 0;
      transform: skewX(60deg);
      width: 100%;
      content: '';
    }

    &::before {
      top: 0;
      transform-origin: bottom right;
      z-index: 1;
      background-color: var(--color-purple-700);
      height: calc(var(--before-height) + 1px);
    }

    &::after {
      bottom: 0;
      transform-origin: top left;
      background-color: var(--color-purple-700);
      height: calc(var(--after-height) + 1px);
    }

    &[data-month-top-out-of-view='1']::before {
      background-color: var(--color-turquoise-700);
    }

    &[data-month-top-in-of-view='1']::after {
      background-color: var(--color-turquoise-700);
    }

    &[data-month-top-out-of-view='2']::before {
      background-color: var(--color-sand-700);
    }

    &[data-month-top-in-of-view='2']::after {
      background-color: var(--color-sand-700);
    }
  }
}

.monthsWrapper {
  @include from-breakpoint(lg) {
    height: 100%;

    &::after {
      display: block;
      position: absolute;
      bottom: 0;
      left: -1px;
      background-color: var(--color-bg-zigzag-hero);
      width: 100%;
      height: var(--after-height);
      content: '';
    }
  }
}

.monthContainer {
  --month-after-height: 16px;

  position: relative;
  transform: skewX(359deg);
  margin-bottom: var(--month-after-height);
  background-color: var(--color-bg-primary);
  padding: var(--vertical-spacing) var(--horizontal-spacing);

  &::after {
    display: block;
    position: absolute;
    bottom: calc(-1 * var(--month-after-height));
    left: 0;
    transform: skewX(44deg);
    transform-origin: top left;
    background-color: var(--color-purple-700);
    width: 100%;
    height: var(--month-after-height);
    content: '';
  }

  &:nth-child(2) {
    background-color: var(--color-turquoise-500);

    &::after {
      background-color: var(--color-turquoise-700);
    }
  }

  &:nth-child(3) {
    background-color: var(--color-sand-500);

    &::after {
      background-color: var(--color-sand-700);
    }
  }

  @include until-breakpoint(lg) {
    &:nth-child(2) {
      background-color: var(--color-turquoise-500);

      &::after {
        background-color: var(--color-turquoise-700);
      }
    }

    &:nth-child(3) {
      background-color: var(--color-sand-500);

      &::after {
        background-color: var(--color-sand-700);
      }
    }
  }

  @include from-breakpoint(lg) {
    transform: none;
    margin: 0;
    padding: var(--vertical-spacing) var(--horizontal-spacing);
    min-height: 100%;

    &::after {
      display: none;
    }

    [data-month-top-in-of-view='2'] &:first-child {
      background-color: var(--color-sand-500);
    }
  }

  @include from-breakpoint(2xl) {
    [data-month-top-in-of-view='2'] &:first-child {
      background-color: var(--color-purple-500);
    }
  }
}

.monthTitle,
.monthLabel {
  text-transform: uppercase;
  font-weight: 700;
}

.monthTitle {
  margin-bottom: spacing(8);
  text-align: center;
  color: var(--color-yellow-500);
  font-size: 3rem;

  @include until-breakpoint(lg) {
    @include visually-hidden;
  }
}

.monthContent {
  display: flex;
  align-items: flex-start;
}

.monthLabel {
  transform: rotateZ(180deg);
  writing-mode: vertical-rl;
  line-height: 0.7;
  color: var(--color-white);
  font-size: 2rem;
  font-weight: 700;

  @include from-breakpoint(sm) {
    font-size: 2.5rem;
  }

  @include from-breakpoint(lg) {
    font-size: 4.5rem;
  }
}

.highlights {
  display: grid;
  flex: 1;
  gap: spacing(2);
  padding-left: var(--horizontal-spacing);

  @include from-breakpoint(xl) {
    gap: spacing(4);
  }

  @include from-breakpoint(2xl) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.linkWrapper {
  margin-top: spacing(4);
  text-align: center;
}

.link {
  text-transform: uppercase;

  // Important here because of the specificity in button.module.scss
  text-decoration: none !important;
  color: var(--color-fg-zigzag-hero);

  &:hover,
  &:focus-visible {
    text-decoration: underline !important;
    color: var(--color-yellow-500);
  }

  @include from-breakpoint(lg) {
    font-size: 1.25rem;
  }
}
